<template>
	<div class="flex-col page">
		<div class="flex-col group_1">
			<div class="flex-col text-wrapper">
				<span class="text_1">申请成为维保人</span>
			</div>
			<div class="box_1">
				<span class="text_2">请输入您的真实信息</span>
				<div class="flex-row section_3" @click="popup">
					<img src="../assets/fang.png" class="image_4" />
					<span class="text_7">{{ chose }}</span>
					<span></span>
				</div>
				<div class="flex-row section_2">
					<img src="../assets/user.png" class="image_4" />
					<input
						type="text"
						class="text_3"
						v-model="userName"
						placeholder="请输入维保人姓名"
					/>
				</div>
				<div class="flex-row section_2">
					<van-radio-group
						v-model="radio2"
						direction="horizontal"
						@change="genders(radio2)"
					>
						<van-radio name="1">男</van-radio>
						<van-radio name="2">女</van-radio>
					</van-radio-group>
				</div>
				<div class="flex-row section_3">
					<img src="../assets/suo_2.png" class="image_4" />
					<input
						type="text"
						class="text_4"
						v-model="phone"
						placeholder="请输入手机号"
					/>
				</div>
				<div class="flex-row section_3">
					<img src="../assets/suo_2.png" class="image_4" />
					<input
						type="text"
						class="text_4"
						v-model="identity"
						placeholder="请输入身份证号"
					/>
				</div>
				<div class="flex-row section_3">
					<img src="../assets/dun.png" class="image_4" />
					<input
						type="text"
						class="text_4"
						v-model="codes"
						placeholder="请输入图片验证码"
						@blur="codephoto(codes)"
					/>
					<div @click="shorts()">
						<img :src="srcc" alt="" class="short" />
					</div>
				</div>
				<div class="flex-row section_3">
					<img src="../assets/dun.png" class="image_4" />
					<input
						type="text"
						class="text_4"
						v-model="code"
						placeholder="请输入验证码"
						@blur="codess()"
					/>
					<div class="flex-col items-center text-wrapper_1" @click="vcode()">
						<span @click="getCode">{{ count }}</span>
					</div>
				</div>
				<div class="flex-row section_3">
					<img src="../assets/suo.png" class="image_4" />
					<input
						type="text"
						class="text_4"
						v-model="password"
						placeholder="请输入密码"
					/>
				</div>
				<div class="flex-row section_3">
					<img src="../assets/suo.png" class="image_4" />
					<input
						type="text"
						class="text_4"
						v-model="Npassword"
						placeholder="请输入确认密码"
						@blur="pwd()"
					/>
				</div>
				<span class="text_10">成为维保人需持有操作证才能上岗修梯</span>
				<div class="flex-col items-center section_6">
					<van-uploader
						v-model="fileList"
						:after-read="afterRead"
						multiple
						:max-count="2"
					/>
					<span class="text_11">上传</span>
				</div>
				<div
					:class="['flex-col', 'items-center', 'button', { button_1: isLogin }]"
					@click="submit(isLogin)"
				>
					<span>提交申请</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { apply, photo, short, shorty } from "@/api/Login/Apply";
import Vue from "vue";
import { RadioGroup, Radio, Toast } from "vant";
export default {
	data() {
		return {
			userName: "",
			phone: "",
			code: "",
			codes: "",
			// showPicker: '选择维保公司',
			password: "",
			Npassword: "",
			identity: "", //身份证
			show: true,
			count: "获取验证码",
			timer: null,
			columnsid: "",
			vanShow: false,
			fileList: [],
			srcc: "",
			codep: "",
			flag: false,
			key: "",
			str: false,
			photos: "",
			chose: "选择维保公司",
			radio2: "", //男女
		};
	},
	mounted() {
		if (localStorage.getItem("token")) {
			localStorage.removeItem("token");
		}
		// 图片验证码
		photo().then((res) => {
			this.srcc = res.data.Data.content;
			console.log(res.data.Data.code);
			console.log(res.data.Data.key);
			this.codep = res.data.Data.code;
			this.key = res.data.Data.key;
		});
		if (sessionStorage.getItem("address")) {
			this.chose = sessionStorage.getItem("address");
		} else {
			this.chose = "选择维保公司";
		}
	},
	computed: {
		isLogin() {
			let boo = false;
			if (
				this.password &&
				this.Npassword &&
				this.userName &&
				this.phone &&
				this.code &&
				this.chose
			) {
				boo = true;
			}
			return boo;
		},
	},
	name: "HelloWorld",
	props: {
		msg: String,
	},
	methods: {
		getCode() {
			// if()
			if (this.codep === this.codes) {
				// 图片验证码
				short({ phone: this.phone, code: this.codes, key: this.key }).then(
					(res) => {
						console.log(res);
						if (res.data.Result === 200) {
							const TIME_COUNT = 60;
							if (!this.timer) {
								this.count = TIME_COUNT;
								this.show = false;
								this.timer = setInterval(() => {
									if (this.count > 0 && this.count <= TIME_COUNT) {
										this.count--;
									} else {
										this.show = true;
										this.count = "获取验证码";
										clearInterval(this.timer);
										this.timer = null;
									}
								}, 1000);
							}
						} else {
							Toast.fail("请检查手机号是否正确");
						}
					}
				);
			} else {
				Toast.fail("请输入正确的图片验证码");
			}
		},
		popup() {
			this.$router.push("/maintenance/choose");
		},
		onChange(picker, value, index) {
			this.showPicker = value;
			this.columnsid = value;
		},
		afterRead(file) {
			file.status = "uploading";
			file.message = "上传中...";

			setTimeout(() => {
				file.status = "done";
				file.message = "上传失败";
			}, 1000);
		},
		// 总体提交
		submit(iln) {
			// if(sessionStorage.getItem('address-id')&&this.password===this.Npassword){
			//   console.log('123');
			// }
			if (iln) {
				if (
					sessionStorage.getItem("address-id") &&
					this.password === this.Npassword
				) {
					apply({
						RealName: this.userName,
						Phone: this.phone,
						code: this.code,
						password: this.password,
						IdNumber: this.identity,
						Gender: this.radio2,
						company_id: Number(sessionStorage.getItem("address-id")),
						IdentificationPhoto: this.photos,
					}).then((res) => {
						console.log(res);
						if (res.data.Result === 200) {
							sessionStorage.removeItem("address");
							sessionStorage.removeItem("address-id");
							this.$router.push("/maintenance/loading");
						}
					});
				} else {
					Toast.fail("请检查内容是否正确");
				}
			} else {
				Toast.fail("有选项没有填写哦");
			}
		},
		// 短信验证码
		// vcode(){
		// short({phone:this.phone,code:this.codes,key:this.key}).then(res=>{
		//   console.log(res);
		// })
		// },
		// 验证短信验证码
		codess() {
			shorty({
				phone: this.phone,
				code: this.code,
			}).then((res) => {
				console.log(res);
			});
		},
		shorts() {
			// 图片验证码
			photo().then((res) => {
				this.srcc = res.data.Data.content;
				console.log(res.data.Data.code);
				console.log(res.data.Data.key);
				this.codep = res.data.Data.code;
				this.key = res.data.Data.key;
			});
		},
		// 验证图片
		codephoto(value) {
			if (this.codep !== value) {
				Toast.fail("验证码错误");
			}
		},
		// 判断两次密码是否一致
		pwd() {
			if (this.password !== this.Npassword) {
				Toast.fail("两次密码不一致");
			}
		},
		// 男女
		genders(s) {
			console.log(this.radio2);
		},
	},
};
Vue.use(Toast);
Vue.use(Radio);
Vue.use(RadioGroup);
</script>

<style scoped>
@import "../assets/common.css";
.image_4 {
	width: 1.5rem;
	height: 1.5rem;
}
.page {
	width: 100%;
}
.header {
	padding-top: 0.063rem;
	color: rgb(0, 0, 0);
	font-size: 1rem;
	line-height: 1rem;
	white-space: nowrap;
	height: 2.81rem;
}
.group_1 {
	flex: 1 1 auto;
	overflow-y: auto;
}
.section_1 {
	padding: 0.88rem 0.81rem;
	background-color: rgb(237, 237, 237);
}
.box_1 {
	overflow-x: hidden;
	margin-top: -1.5rem;
	background-color: #f5f5f5;
	border-radius: 1.2rem 1.2rem 0 0;
	padding-top: 2.5rem;
}
.section_2,
.section_3 {
	margin: 1.56rem 1.25rem 0;
	padding: 1rem 0.88rem 0.94rem;
	color: rgb(153, 153, 153);
	font-size: 0.88rem;
	line-height: 0.88rem;
	white-space: nowrap;
	background: #fff;
	border-radius: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.section_6 {
	margin-left: 1.25rem;
	padding: 1.5rem 0 1.94rem;
	color: rgb(112, 112, 112);
	font-size: 0.94rem;
	line-height: 0.94rem;
	white-space: nowrap;
	background: #fff;
	border-radius: 0.5rem;
	width: 7.63rem;
}
.text-wrapper_1 {
	padding: 0.56rem 0 0.5rem;
	color: rgb(255, 255, 255);
	font-size: 0.88rem;
	line-height: 0.88rem;
	white-space: nowrap;
	background: #0bc7ae;
	border-radius: 0.4rem;
	width: 5rem;
	height: 1.94rem;
}
.button {
	margin: 3.69rem 1.25rem 8rem;
	padding: 1.13rem 0 1rem;
	color: rgb(0, 0, 0);
	font-size: 1rem;
	font-weight: 500;
	line-height: 1rem;
	white-space: nowrap;
	border-radius: 0.3rem;
	background: #e2e2e2;
	background-repeat: no-repeat;
}
.button_1 {
	background-color: #005ddb;
	color: #fff;
}
.image_1 {
	margin-right: 0.25rem;
	align-self: center;
	width: 1.13rem;
	height: 0.25rem;
}
.text-wrapper {
	padding: 5.88rem 0 6.75rem;
	color: rgb(255, 255, 255);
	font-size: 1.88rem;
	line-height: 1.75rem;
	white-space: nowrap;
	background: #0355d7 url(../assets/bg.png) right no-repeat;
}
.text_2 {
	color: rgb(0, 0, 0);
	font-size: 1.25rem;
	line-height: 1.19rem;
	white-space: nowrap;
	margin-left: 1.31rem;
}
.text_3,
.text_4 {
	flex: 1;
	margin-left: 0.5rem;
	border: 0;
	outline: none;
}
.text_10 {
	width: 100%;
	display: block;
	text-align: center;
	margin: 1rem 0;
}
.text_11 {
	display: inline-block;
}
.image {
	margin-bottom: 0.13rem;
	width: 0.81rem;
	height: 0.81rem;
}
.image_12 {
	width: 2.88rem;
	height: 2.31rem;
}
.text {
	margin-left: 0.69rem;
}
.text_1 {
	text-align: left;
	margin-left: 1.31rem;
}
.text_7 {
	margin: 0.27rem 0 0.27rem 0.1rem;
}
.short {
	width: 5rem;
	height: 2rem;
}
.wt {
	color: red;
	text-indent: 2rem;
}
</style>
