import request from '../../request/request'
export function apply(data){
    return request({
        url:'/CompanyStaff/SaveStaffApply',
        method:'post',
        data
    })
}
export function photo(data){
    return request({
        url:'/GetImageCaptcha',
        method:'post',
        data
    })
}
export function short(data){
    return request({
        url:'/GetSmsCaptcha',
        method:'post',
        data
    })
}
export function shorty(data){
    return request({
        url:'/VerifySmsCaptcha',
        method:'post',
        data
    })
}

